import React from "react"
import Menu from './menu'
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image"



export default function Layout({ children }) {
	const data = useStaticQuery(graphql`
query {
	mobileImage: file(relativePath: { eq: "header.jpg" }) {
		childImageSharp {
			fluid(maxWidth: 980, quality: 100) {
				...GatsbyImageSharpFluid
			}
		}
	}
	desktopImage: file(
		relativePath: { eq: "header.jpg" }
	) {
		childImageSharp {
			fluid(maxWidth: 1000, quality: 100) {
				...GatsbyImageSharpFluid
			}
		}
	}
}
`);

	const sources = [
		data.mobileImage.childImageSharp.fluid,
		{
			...data.desktopImage.childImageSharp.fluid,
			media: `(min-width:768px}`
		}
	]


	return (
		<div id="page">
			<header id="branding">
				<Img fluid={sources} alt="programHer" />

				<nav id="menu">
					<Menu />
				</nav>
			</header>
			<div id="content">
			{children}
			</div>
		</div>
	)
}
