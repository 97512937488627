import React, { Component } from "react"
import { Link } from "gatsby"


class Menu extends Component {
	constructor(props) {
		super(props);
		this.state = {
			open: false
		}
	}
	close() {
		this.setState({
			open:false
		})
	}
	toggle() {
		this.setState({
			open: !this.state.open
		})
	}

	render = () => {
		return (
			<div>
				<button className='nav-menu-button' onClick={()=>this.toggle()}>
					<span></span>
					<span></span>
					<span></span>
				</button>

				<div role="button" 
					className={'nav-menu '+(this.state.open ? 'open' : "")}
					onClick={()=>this.close()}
					onKeyDown={()=>this.close()}
					tabIndex="0"
					>
					<ul>
						<li><Link to={`/`} activeClassName="active" 
			>Home</Link></li>
						<li><Link to={`/blogs/`} activeClassName="active" partiallyActive={true}
			>Blog</Link></li>
						<li><Link to={`/links/`} activeClassName="active" partiallyActive={true}
			>Links</Link></li>

						<li><a href="https://photos.pebbz.com/">Photos</a></li>
						<li><a href="https://www.linkedin.com/in/pebbz/">Hire me</a></li>
					</ul>
				</div>
			</div>
		)
	}
}
export default Menu;
